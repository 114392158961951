<template>
  <common-layout>
    <slot />
  </common-layout>
</template>

<script setup lang="ts">
import { useLocaleHead } from '#i18n';
import { useI18n } from 'vue-i18n';
import CommonLayout from '~/shared/components/CommonLayout.vue';
import { useHead } from '#imports';
import { useSeoMetaData } from '~/shared/composable/useSeoMetaData';

const head = useLocaleHead({
  addSeoAttributes: true,
});
useHead(head);

const { t } = useI18n();

useSeoMetaData({
  title: t('common.site.title'),
  description: t('common.site.description'),
  relativeUrl: '/',
});
</script>
